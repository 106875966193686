import BaseService from '@src/services/BaseService'

class PosChainHasBusService extends BaseService {
  constructor() {
    super('posc-has-bus')
  }

  getByPosC(id) {
    return this.axios.post(`${this.getUrl()}/get-by-posc/${id}`,)
}
}

export default new PosChainHasBusService()
