<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        size="xl">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-tabs
                    id="businessunit-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv">
                    <b-tab :title="i18nInformation">
                        <b-row>
                            <b-col class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nName"
                                    label-for="name-input"
                                    class="required label-pdv">
                                    <b-form-input
                                        id="name-input"
                                        v-model.trim="posChainModal.name"
                                        class="input-pdv-blue"
                                        name="name"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-sm-12 col-md-8">
                                <data-mode-vuetable
                                    ref="flagRelationVuetable"
                                    v-model="posChainModal.flags"
                                    :fields="flagFields"
                                    :disabled="submitLoading"
                                    @onClickDelete="() => {}"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="i18nRevenues">
                        <b-row>
                            <b-col class="col-sm-12 col-md-4">
                                <b-form-group
                                    :label="i18nBusinessUnit"
                                    label-for="add-business-unit-input"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_business_unit')">
                                    <multiSelectWithService
                                        :id="'add-business-unit-input'"
                                        ref="businessUnitMultiselect"
                                        v-model="revenueModal.business_unit"
                                        :input-function="$v.revenueModal.business_unit.id.$touch"
                                        :select-class="validationClass($v.revenueModal.business_unit.id)"
                                        :service="'business-units'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                                <b-form-group
                                    :label="i18nRevenue"
                                    label-for="modal-revenue"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_revenue')"
                                >
                                    <money
                                        id="modal-revenue"
                                        v-model.trim="revenueModal.revenue"
                                        v-bind="money"
                                        name="revenue"
                                        class="form-control input-pdv-blue"
                                        :class="validationClass($v.revenueModal.revenue)"
                                        aria-describedby="input-1-live-feedback"
                                        :disabled="submitLoading"
                                        :state="validateField('revenue', 'revenueModal')"
                                    />
                                </b-form-group>
                                <add-button
                                    ref="addButton"
                                    :busy="submitLoading"
                                    @onClick="handleOk"
                                />
                            </b-col>
                            <b-col class="col-sm-12 col-md-8">
                                <data-mode-vuetable
                                    ref="revenueRelationVuetable"
                                    v-model="posChainModal.point_of_sale_chain_has_business_units"
                                    :fields="revenueFields"
                                    :busy="revenueLoading"
                                    :busy-delete="deleteLoading"
                                    :display-delete-button="!submitLoading"
                                    :disabled="submitLoading || deleteLoading"
                                    @onClickDelete="handleRemove"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
    </form-modal>
</template>

<script>
import { Money } from 'v-money'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import { numeric, decimal, minValue, required } from 'vuelidate/lib/validators'
import PosChainHasBusService from '@src/services/PosChainHasBusService'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import AddButton from '@/src/components/AddButton'
import multiSelectWithService from '@src/components/multiSelectWithService'
import { i18n } from '@src/i18n'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        DataModeVuetable,
        AddButton,
        multiSelectWithService,
        Money,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        fieldsValidation
    ],
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    name: '',
                    flags: [],
                    point_of_sale_chain_has_business_units: [],
                }
            },
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            posChainModal: this.modal,
            revenueModal: {},
            tabIndex: 0,
            submitLoading: false,
            deleteLoading: false,
            revenueLoading: false,
            money: {
                decimal: i18n.t('CURRENCY.decimal'),
                thousands: i18n.t('CURRENCY.thousands'),
                prefix: i18n.t('CURRENCY.prefix'),
                suffix: i18n.t('CURRENCY.suffix'),
                precision: 2,
                masked: false,
            },
            flagFields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.flag'),
                    sortable: true,
                },
            ],
            revenueFields: [
                {
                    key: 'business_unit.name',
                    label: this.getI18n(i18nCommon, 'business_unit'),
                    sortable: true,
                },
                {
                    key: 'revenue',
                    label: this.getI18n(i18nKey, 'FIELDS.revenue'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
    validations: {
        revenueModal: {
            business_unit: {
                id: {
                    required,
                    numeric,
                    minValue: minValue(1)
                },
            },
            revenue: {
                required,
                decimal,
                minValue: minValue(0.01)
            },
        },
    },
    computed: {
        i18nTitleModal() {
            return this.posChainModal.name
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },

        i18nPosChain() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.chain',
                modifier: 1,
            })
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nRevenue() {
            return this.getI18n(i18nKey, 'FIELDS.revenue')
        },
        i18nRevenues() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'FIELDS.revenue',
                modifier: 2,
            })
        },
    },
    methods: {
        showModal() {
            this.tabIndex = 0
            this.submitLoading = false
            this.deleteLoading = false
            this.revenueLoading = false
            this.posChainModal = this.modal
            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            this.posChainModal = Object.assign(this.posChainModal, {
                id: null,
                name: '',
                flags: [],
                point_of_sale_chain_has_business_units: [],
            })

            this.resetValidation()
        },
        cleanRevenueModal() {
            this.revenueModal = Object.assign(this.revenueModal, {
                id: null,
                revenue: 0,
                business_unit: null
            })
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.revenueModal.$touch()
            if (!this.$v.revenueModal.$invalid && !this.submitLoading) {
                this.handleSubmit()
            }
        },
        replaceModalData(data) {
            Object.assign(this.posChainModal, data)
        },
        async handleSubmit() {
            this.submitLoading = true

            /* eslint-disable camelcase */
            const posChainRevenueInfo = {
              point_of_sale_chain_id: this.posChainModal.id,
              business_unit_id: this.revenueModal.business_unit.id,
              revenue: this.revenueModal.revenue,
            }

            const response = await PosChainHasBusService.create(posChainRevenueInfo)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.reloadRevenues()
                this.$emit('refresh')
                this.resetValidation()
                this.cleanRevenueModal()
                this.positiveFeedback(this.i18nRevenue, 'created', 0)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nRevenue, response.data.errors)
            }

            this.submitLoading = false
        },
        async handleRemove(id) {
            const index = this.posChainModal.point_of_sale_chain_has_business_units.findIndex(find => find.id === id);

            if (index > -1) {
                this.deleteLoading = true
                const response = await PosChainHasBusService.delete(id)
                    .then((response) => response)
                    .catch((error) => error)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.$emit('refresh')
                    this.posChainModal.point_of_sale_chain_has_business_units.splice(index, 1);
                    this.positiveFeedback(this.i18nRevenue, 'deleted', 3)
                } else if (statusCode === '422') {
                    this.modalResponseErrors = response.data.errors
                    this.warningFeedbackApi(this.i18nRevenue, response.data.errors)
                }

                this.deleteLoading = false
            }
        },
        reloadRevenues() {
            this.revenueLoading = true
            PosChainHasBusService.getByPosC(this.posChainModal.id)
                .then((response) => {
                    this.posChainModal.point_of_sale_chain_has_business_units = response.data.data
                    this.revenueLoading = false
                })
                .catch((error) => {
                    this.warningFeedbackApi(this.i18nRevenue, error.data.errors)
                    this.revenueLoading = false
                })
        }
    },
}
</script>

<style scoped>

</style>
