<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main.vue'
import PosChainsService from '@src/services/PosChainsService'
import FilterBar from '@src/components/FilterBar'
import posChainModal from '@views/intelligence/posChain/posChainModal.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import { debounce } from 'lodash'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        posChainModal,
        GridFormModal,
        FilterBar,
        CountTableButton,
        MoreTableButton
    },
    mixins: [pagination, swalFeedback],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modal: {
                id: null,
                name: '',
                flags: [],
                point_of_sale_chain_has_business_units: [],
            },
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nKey, 'TITLES.chain'),
                    sortField: 'name',
                },
                {
                    name: 'flags',
                    title: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.flag',
                        modifier: 2,
                    })
                },
                {
                    name: 'point_of_sale_chain_has_business_units',
                    title: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'FIELDS.revenue',
                        modifier: 2,
                    })
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            fieldsFlagModal: [
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.flag'),
                    sortable: true,
                },
            ],
            fieldsRevenueModal: [
                {
                    key: 'business_unit.name',
                    label: this.getI18n(i18nCommon, 'business_unit'),
                    sortable: true,
                },
                {
                    key: 'revenue',
                    label: this.getI18n(i18nKey, 'FIELDS.revenue'),
                    sortable: true,
                    formatter: this.getI18nCurrency,
                },
            ],
            filterText: '',
            additionalParameters: {
                with: [
                    'flags',
                    'pointOfSaleChainHasBusinessUnits',
                    'pointOfSaleChainHasBusinessUnits.businessUnit',
                ],
            },
        }
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.chain',
                modifier: 2,
            }),
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.chain',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nPosChain() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.chain',
                modifier: 2,
            })
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewPosChain() {
            return this.getI18n(i18nKey, 'BUTTONS.new_chain')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return PosChainsService.fetchVuetable(url, params)
        },
        createAdditionalParameters() {
            this.additionalParameters.search = ''
            if (this.filterText && this.filterText.length > 0) {
                this.additionalParameters.search = `name:${this.filterText}`
            }
            return this.additionalParameters
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        cleanModal() {
            this.$nextTick(() => this.$refs.posChainModal.cleanModal())
        },
        showModal() {
            this.$nextTick(() => this.$refs.posChainModal.showModal())
        },
        openModalToEdit(data) {
            this.replaceModalData(data)
            this.showModal()
        },
        openModalFlags(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridFlagsModal.showModal())
        },
        openModalRevenues(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridRevenuesModal.showModal())
        },
        replaceModalData(data) {
            Object.assign(this.modal, {
                id: data.id,
                name: data.name,
                flags: [],
                point_of_sale_chain_has_business_units: []
            })

            Object.assign(this.modal.flags, data.flags);
            Object.assign(this.modal.point_of_sale_chain_has_business_units, data.point_of_sale_chain_has_business_units);
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nPosChain" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="flags" slot-scope="props">
                            <count-table-button :value="props.rowData.flags.length" @onClick="openModalFlags(props.rowData)" />
                        </template>
                        <template slot="point_of_sale_chain_has_business_units" slot-scope="props">
                            <count-table-button :value="props.rowData.point_of_sale_chain_has_business_units.length" @onClick="openModalRevenues(props.rowData)" />
                        </template>
                        <template slot="actions" slot-scope="props" class="text-right">
                            <div class="table-button-container text-right">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <pos-chain-modal
            ref="posChainModal"
            :modal="modal"
            @refresh="reloadTable"
        />
        <grid-form-modal
            ref="gridFlagsModal"
            :modal="modal"
            :items="modal.flags"
            :fields="fieldsFlagModal"
            :title="getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.flag',
                        modifier: 2,
                    })"
        />
        <grid-form-modal
            ref="gridRevenuesModal"
            :modal="modal"
            :items="modal.point_of_sale_chain_has_business_units"
            :fields="fieldsRevenueModal"
            :title="getI18nModified({
                        prefix: i18nKey,
                        suffix: 'FIELDS.revenue',
                        modifier: 2,
                    })"
        />
    </Layout>
</template>
